module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon-32x32.png","icons":[{"src":"/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon-48x48.png","sizes":"48x48","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d325d8d669081c0038516f32f55d1ef2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
